<template>
  <div
    class="sidebar"
    :class="{'d-flex align-items-start flex-column' :
      (breakpoint === 'lg' || breakpoint === 'xl') && !isMobile,
      'pb-4' : isMobile && breakpoint === 'lg' }">
    <!-- User Info -->
    <!-- <div class="user-info mb-auto"> -->
    <div class="user-info">
      <b-row class="v-step-0" no-gutters>
        <b-col>
          <img
            class="img-fluid level-badge"
            :src="userLevel.asset_url"
            title="Level Badge"
            alt="Level, Badge"
          />
        </b-col>
      </b-row>

      <b-row no-gutters>
        <b-col class="vl-left" cols="6"></b-col>
        <b-col class="vl-right" cols="6"></b-col>
      </b-row>
      <b-row class="v-step-1 v-step-8" no-gutters>
        <b-col>
          <router-link to="/profile" title="Profile">
            <img
              class="img-fluid profile-photo"
              :src="profilePhoto"
              alt="Profile Photo"
            />
          </router-link>
        </b-col>
      </b-row>
      
      <b-row no-gutters>
        <b-col class="vl-left" cols="6"></b-col>
        <b-col class="vl-right" cols="6"></b-col>
      </b-row>

      <b-row class="v-step-2" no-gutters>
        <b-col>
          <div class="level-wrapper">
            <p class="level mb-0" title="Current Level">
              {{ userLevel.level }}
            </p>
          </div>
        </b-col>
      </b-row>

      <b-row no-gutters>
        <b-col class="vl-left" cols="6"></b-col>
        <b-col class="vl-right" cols="6"></b-col>
      </b-row>

      <b-row no-gutters>
        <b-col>
          <div class="level-percentage-wrapper">
            <p class="level-percentage mb-0" title="Current Level Progress">
              {{ Math.ceil(expPercentage) + '%' }}
            </p>
          </div>
        </b-col>
      </b-row>
    </div>

    <!-- Navigation -->
    <b-navbar>
      <b-collapse is-nav>
        <b-navbar-nav class="navigation">
          <router-link
            class="nav-link"
            v-for="(nav, index) in navs"
            :key="index"
            :class="nav.step"
            :to="nav.link">
            <img
              class="img-fluid nav-icon"
              :src="nav.icon"
              :alt="nav.name"
              :title="nav.name"
              v-if="nav.name !== 'Logout'"
            />
            <div class="logout-link" v-else> {{ nav.name }}</div>
          </router-link>
          <div class="nav-link v-step-7" title="Logout/Switch">
            <div
              class="logout-link"
              v-if="user.user_type !== 1"
              @click="openSwitchUser">
              Logout
            </div>
            <router-link class="logout-link" to="/logout" v-else>
              Logout
            </router-link>
          </div>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <div class="tour-container">
      <v-tour
        name="gettingStarted"
        :steps="steps"
        :options="tourOptions">
        <template slot-scope="tour">
          <transition name="fade">
            <v-step
              v-if="tour.steps[tour.currentStep]"
              :key="tour.currentStep"
              :step="tour.steps[tour.currentStep]"
              :previous-step="tour.previousStep"
              :next-step="tour.nextStep"
              :stop="tour.stop"
              :skip="tour.skip"
              :is-first="tour.isFirst"
              :is-last="tour.isLast"
              :labels="tour.labels"
              :highlight="tour.highlight"
            >
              <div slot="actions">
                <b-row>
                  <b-col cols="6">
                    <b-button
                      class="tour-buttons"
                      size="sm"
                      variant="primary"
                      @click="tour.previousStep"
                      :disabled="tour.currentStep < 1"
                      block
                    >
                      Previous
                    </b-button>
                  </b-col>
                  <b-col cols="6">
                    <b-button
                      class="tour-buttons"
                      size="sm"
                      variant="primary"
                      @click="profileGettingStarted"
                      block
                      v-if="tour.currentStep === (steps.length - 1)"
                    >
                      Next
                    </b-button>
                    <b-button
                      class="tour-buttons"
                      size="sm"
                      variant="primary"
                      @click="tour.nextStep"
                      block
                      v-else>
                      Next
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </v-step>
          </transition>
        </template>
      </v-tour>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  import { mapGetters } from 'vuex';
  import BreakpointMixin from '@/mixins/BreakpointMixin';
  import LevelUpMixin from '@/mixins/LevelUpMixin';
  require('vue-tour/dist/vue-tour.css');

  import homeIcon from '../../assets/images/new_main_icon.png';
  import categsIcon from '../../assets/images/new_categories_icon.png';
  import musicIcon from '../../assets/images/new_my_music_icon.png';
  import faveIcon from '../../assets/images/favorite_icon.png';

  export default {
    name   : 'AppSidebar',
    mixins : [
      BreakpointMixin,
      LevelUpMixin,
    ],
    props : {
      startTour : {
        type    : Boolean,
        default : false,
      },
    },
    data() {
      return {
        isMobile : false,
        navs     : [ {
          name : 'Home',
          step : 'v-step-3',
          icon : homeIcon,
          link : '/dashboard',
        }, {
          name : 'Categories',
          step : 'v-step-4',
          icon : categsIcon,
          link : '/categories',
        }, {
          name : 'Music',
          step : 'v-step-5',
          icon : musicIcon,
          link : '/music',
        }, {
          name : 'Favorites',
          step : 'v-step-6',
          icon : faveIcon,
          link : '/favorites',
        } ],
        activeStep : null,
        steps      : [
          {
            target : '.v-step-0',
            params : {
              placement       : 'right',
              highlight       : true,
              enableScrolling : false,
            },
            header  : { title : 'Getting Started' },
            content : 'This is your badge. Earn more badges as you level up! ' +
              'Win discounts and prizes (Coming soon...)',
          }, {
            target : '.v-step-1',
            params : {
              placement       : 'right',
              highlight       : true,
              enableScrolling : false,
            },
            header  : { title : 'Profile' },
            content : 'Click this icon to view or edit your profile',
          }, {
            target : '.v-step-2',
            params : {
              placement       : 'right',
              highlight       : true,
              enableScrolling : false,
            },
            header  : { title : 'User Level' },
            content : 'This is your level. Keep watching H3 Videos to ' +
              'Level Up. Walk through the end of this tutorial to earn your ' +
              'first points!',
          }, {
            target : '.v-step-3',
            params : {
              placement       : 'right',
              highlight       : true,
              enableScrolling : false,
            },
            header  : { title : 'Home' },
            content : 'Click this tab to see all the latest Healthy Hip Hop ' +
              'content',
          }, {
            target : '.v-step-4',
            params : {
              placement       : 'right',
              highlight       : true,
              enableScrolling : false,
            },
            header  : { title : 'Categories' },
            content : 'Click this tab to watch all Healthy Hip Hop videos!',
          }, {
            target : '.v-step-5',
            params : {
              placement       : 'right',
              highlight       : true,
              enableScrolling : false,
            },
            header  : { title : 'Music' },
            content : 'Click this tab to listen all Healthy Hip Hop music!',
          }, {
            target : '.v-step-6',
            params : {
              placement       : 'right',
              highlight       : true,
              enableScrolling : false,
            },
            header  : { title : 'Favorites' },
            content : 'Click this tab to see all video and music favorites!',
          }, {
            target : '.v-step-7',
            params : {
              placement       : 'right',
              highlight       : true,
              enableScrolling : false,
            },
            header : {
              title : (this.$store.getters['user/user'].user_type === 1) ?
                'Logout' : 'Switch User',
            },
            content : (this.$store.getters['user/user'].user_type === 1) ?
              'Click this tab to Logout' :
              'Click this tab to Switch User or logout',
            before : () => {
              this.scrollToBottom();
            },
          }, {
            target : '.v-step-8',
            params : {
              placement       : 'right',
              highlight       : true,
              enableScrolling : false,
            },
            header  : { title : 'Are you ready?' },
            content : 'Start with editing your avatar and earn your very ' +
              'first H3 badge!',
          },
        ],
        tourOptions : {
          highlight             : true,
          debug                 : true,
          useKeyboardNavigation : false,
        },
      }
    },
    watch : {
      startTour(value) {
        if (value === true)
          this.showGettingStarted();
      },
      breakpoint(val) {
        if (val == 'lg')
          this.checkIfMobile();
      },
    },
    computed : {
      ...mapGetters({
        user    : 'user/user',
        levels  : 'gamification/levels',
        profile : 'profile/info',
      }),
      userLevel() {
        const lvl = _.find(this.levels, (o) => {
          return o.level == this.$store.getters['user/user'].userLevel.level;
        });

        return lvl || {};
      },
      expPercentage() {
        return (this.userLevelThreshold && this.user.userLevel.exp_points) ?
          (this.user.userLevel.exp_points / this.userLevelThreshold) * 100 : 0;
      },
      profilePhoto() {
        const defaultPhoto = require(
          '../../assets/images/sample_user_profile.png');
        const profilePhoto = (this.profile.user_profiles_id) ?
          this.profile.profile_img_url : this.user.profile_img_url;
        
        return profilePhoto || defaultPhoto;
      },
    },
    methods : {

      /**
       * Show Getting Started Guide
       */
      showGettingStarted() {
        this.$tours['gettingStarted'].start();
      },

      profileGettingStarted() {
        this.$tours['gettingStarted'].stop();
        this.$router.push('/profile');
      },

      /**
       * Open Switch User
       */
      openSwitchUser() {
        this.$emit('openSwitchUser');
      },

      /**
       * Scroll to Bottom
       */
      scrollToBottom() {
        window.scrollTo(0, document.body.scrollHeight);
      },

      /**
       * Check if website is viewed in mobile
       */
      checkIfMobile() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
          .test(navigator.userAgent)) {
          // mobile device
          this.isMobile = true;
        } else {
          // not mobile device
          this.isMobile = false;
        }
      },
    },
    mounted() {
      if (this.startTour && this.$route.path === '/dashboard')
        this.showGettingStarted();
      
      this.$store.dispatch('gamification/getLevels');

      this.checkIfMobile();
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  .sidebar {
    position: fixed;
    z-index: 10;
    height: 100vh;
  }
  .user-info {
    margin-bottom: 0.75rem;
    padding: 1rem;
    background-color: $nav-blue-1;
    background-image: linear-gradient(45deg, $nav-blue-1, $nav-blue-2);
    box-shadow: 2px 2px 10px rgba(142, 152, 156, 0.6);
    border-bottom-right-radius: 2rem;
    text-align: center;
  }
  .vl-left {
    height: 1em;
    border-right: 1px solid #fff;
  }
  .vl-right {
    height: 1em;
    border-left: 1px solid #fff;
  }
  .level-badge {
    width: 55%;
  }
  .profile-photo {
    padding: 0.25em;
    height: 4em;
    background-color: rgba(225, 225, 225, 0.5);
    border: 2px solid #fff;
    border-radius: 2em;
  }
  .level-wrapper {
    margin: 0 auto;
    height: 2.25em;
    width: 2.25em;
    background-color: $level-color-bg;
    border: 2px solid #fff;
    border-radius: 2em;
  }
  .level-percentage-wrapper {
    margin: 0 auto;
    height: 2.25em;
    width: 2.25em;
    background-image: $level-percentage-color-bg;
    border: 2px solid #fff;
    border-radius: 2em;
  }
  .level, .level-percentage {
    color: #fff;
  }
  .navbar {
    padding: 0;
    width: 100%;
  }
  .nav-link {
    background-color: $nav-blue-1;
    background-image: linear-gradient(45deg, $nav-blue-1, $nav-blue-2);
    box-shadow: 2px 2px 10px rgba(142, 152, 156, 0.6);
    border-bottom-right-radius: 2rem;
    color: #fff !important;
    text-align: center;
    overflow: hidden;

    &:hover {
      cursor: pointer;
      background-image: linear-gradient(45deg, $nav-pink-1, $nav-pink-2);
    }

    .logout-link {
      color: #fff !important;
      &:hover {
        text-decoration: none;
      }
    }
  }

  .nav-link.router-link-exact-active {
    background-image: linear-gradient(45deg, $nav-pink-1, $nav-pink-2);
  }
  .logout-btn {
    &:hover {
      cursor: pointer;
    }
  }

  .tour-buttons {
    font-size: 1em !important;
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {
    .sidebar {
      top: 0;
      width:100%;
      height: auto; //3.7rem;
    }
    .user-info {
      display: none;
    }
    .navbar {
      position: fixed;
      top: 0;
    }
    .navbar-nav {
      width: 100%;
    }
    .nav-link {
      display: block;
      width: 100%;
      border-bottom-right-radius: 0;
      img {
        padding: 0.5rem 1.5rem;
      }
      .logout-link {
        position: relative;
        top: 25%;
        bottom: 25%;
        font-size: 1.2em !important;
      }
    }
  }
  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    .user-info {
      display: none;
    }
    .sidebar {
      width: 100%;
      height: auto;
    }
    .navbar-nav {
      width: 100% !important;
    }
    .nav-link {
      display: block;
      width: 100%;
      border-bottom-right-radius: 0;
      img {
        padding: 0.5rem 1.75rem;
      }
      .logout-link {
        position: relative;
        top: 25%;
        font-size: 1.5em !important;
      }
    }
  }
  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    .user-info {
      display: none;
    }
    .sidebar {
      width: 100%;
    }
    .navbar-nav {
      width: 100% !important;
    }
    .nav-link {
      display: block;
      width: 100%;
      border-bottom-right-radius: 0;
      img {
        padding: 0.5rem 2.5rem;
      }
      .logout-link {
        font-size: 1.5em !important;
      }
    }
  }
  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {
    .user-info {
      display: inline-block;
    }
    .sidebar {
      top: 0;
      left: 0;
      width: calc(#{$sidebar-width} + 2em);
    }
    .level-badge {
      width: 4em;
    }
    .level-wrapper, .level-percentage-wrapper {
      height: 2.75em;
      width: 2.75em;
    }
    .vl-left, .vl-right {
      height: 1em !important;
    }
    .profile-photo {
      height: 6em;
      border-radius: 3em;
    }
    .level, .level-percentage {
      padding: 0.55rem 0.4rem;
      font-size: 1em !important;
    }
    .navbar {
      position: relative;
      left: 0;
    }
    .navbar-nav {
      display: block;
    }
    .nav-link {
      display: block !important;
      margin-bottom: 0.55rem;
      width: calc(#{$sidebar-width} + 2em) !important;
      border-bottom-right-radius: 2rem;
      img {
        padding: 0.5rem 2.5rem;
        background-color: transparent;
      }
      .logout-link {
        padding: 0.45rem;
      }
    }
    .content-container {
      padding-left: calc(#{$sidebar-width} + 2em);
    }
  }
  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    .user-info {
      padding: 1rem 1.5rem;
    }
    .level-badge {
      width: 3em;
    }
    .profile-photo {
      height: 4.5em;
      border-radius: 3em;
    }
    .level, .level-percentage {
      padding: 0.58rem 0.4rem;
      font-size: 1em !important;
    }
    .nav-link {
      img {
        padding: 0.5rem 2.75rem;
      }
      .logout-link {
        padding: 0.25rem;
        font-size: 1em !important;
      }
    }
  }
  @media only screen and (min-width: $special) {
    .sidebar {
      top: 0;
      left: 0;
      width: $sidebar-width !important;
    }
    .user-info {
      padding: 1rem;
    }
    .content-container {
      margin-left: calc(#{$sidebar-width} - 1em) !important;
    }
    .profile-photo {
      padding: 0.25em;
      height: 4em;
      border-radius: 3em;
    }
    .level-badge {
      width: 2.5em;
    }
    .vl-left, .vl-right {
      height: 0.85em !important;
    }
    .nav-link {
      display: block !important;
      width: $sidebar-width !important;
      img {
        padding: 0.5rem 1.75rem;
      }
    }
  }
  @media only screen and (min-width: $xl2-min) {
    .sidebar {
      top: 0;
      left: 0;
      width: $sidebar-width;
    }
    .user-info {
      padding: 4rem 1.25rem;
    }
    .level-wrapper, .level-percentage-wrapper {
      height: 2.5em;
      width: 2.5em;
    }
    .level, .level-percentage {
      padding: 0.5rem;
      font-size: 1em !important;
    }
    .vl-left, .vl-right {
      height: 0.85em !important;
    }
    // .navbar-nav {
    //   display: block;
    // }
    .nav-link {
      display: block !important;
      width: $sidebar-width !important;
      img {
        height: 2.5em;
      }
      .logout-link {
        padding: 0.5rem;
      }
    }
  }
  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {
    .sidebar {
      top: 0;
      left: 0;
      width: calc(#{$sidebar-width} + 2em) !important;
    }
    .user-info {
      padding: 5rem 1rem;
    }
    .profile-photo {
      padding: 0.5em;
      height: 6em;
      border-radius: 3em;
    }
    .level-wrapper, .level-percentage-wrapper {
      height: 3em;
      width: 3em;
    }
    .level, .level-percentage {
      padding: 0.75rem 0.5rem;
      font-size: 1.1em !important;
    }
    .vl-left {
      height: 1em !important;
      border-right: 1.5px solid #fff;
    }
    .vl-right {
      height: 1em !important;
      border-left: 1.5px solid #fff;
    }
    .nav-link {
      display: block !important;
      width: calc(#{$sidebar-width} + 2em) !important;
      img {
        padding: 0.5rem 2.5rem;
        height: 3em;
      }
      .logout-link {
        padding: 1rem;
        font-size: 1.25em !important;
      }
    }
  }
  @media only screen and (min-width: $md-min) and (max-height: 700px) {
    .sidebar {
      position: absolute;
    }
  }
</style>